module.exports = [{
      plugin: require('/home/gareth/Documents/Personal/gandrew2019-blog/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gareth/Documents/Personal/gandrew2019-blog/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-katex","options":{"strict":false}}]},
    },{
      plugin: require('/home/gareth/Documents/Personal/gandrew2019-blog/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato","Merriweather"]}},
    }]
