/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { Styled, jsx } from "theme-ui"
import Img from "gatsby-image"
import Menu from "./Menu"

export default () => (
  <header
    sx={{
      variant: "styles.header",
      backgroundColor: "#fff",
      mb: 4,
    }}
  >
    <Menu />
  </header>
)
