import github from "typography-theme-github"
import Typography from "typography"

const theme = {
  ...github,
  baseFontSize: "18px",
  headerFontFamily: ["Lato"],
  bodyFontFamily: ["Merriweather"],
  overrideThemeStyles: ({ rhythm }, options) => ({
    "h2,h3": {
      marginBottom: rhythm(2),
      marginTop: rhythm(2),
    },
  }),
}

console.log("Typography Theme", theme)

// const typography = new Typography(theme)

// Hot reload typography in development.
// if (process.env.NODE_ENV !== `production`) {
// typography.injectStyles()
// }

export default theme
