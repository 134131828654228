/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { Styled, jsx } from "theme-ui"

const Title = () => (
  <div
    sx={{
      flex: "1 1 auto",
    }}
  >
    <h1 sx={{ variant: "styles.title" }}>Gareth Andrew</h1>
  </div>
)

export default () => (
  <div
    sx={{
      variant: "styles.shadowBox",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      px: 3,
    }}
  >
    <Title />
    <Link
      to="/"
      sx={{
        variant: "styles.navlink",
        p: 2,
      }}
    >
      Blog
    </Link>
    <Link
      to="/about"
      sx={{
        variant: "styles.navlink",
        p: 2,
      }}
    >
      About
    </Link>
  </div>
)
