/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { Styled, jsx } from "theme-ui"
import Header from "./Header"
import "katex/dist/katex.min.css"

const Layout = ({ children }) => {
  return (
    <div
      sx={{
        backgroundImage: "url(/backgrounds/what-the-hex.png)",
        minHeight: "100vh",
      }}
    >
      <div
        sx={{
          maxWidth: "66em",
          margin: "0 auto",
        }}
      >
        <Header />
        {children}
      </div>
    </div>
  )
}

export default Layout
