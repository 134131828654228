import { toTheme } from "@theme-ui/typography"
import typography from "../components/theme/typography"
import { base } from "@theme-ui/presets"

const theme = {
  ...base,
  ...toTheme(typography),
  styles: {
    ...base.styles,
    navlink: {
      color: "#000",
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    title: {
      margin: 0,
      paddingTop: "10px",
      background: "none",
      outline: 0,
      border: 0,
      color: "#000",
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    shadowBox: {
      boxShadow: "17px 19px 25px -12px rgba(0,0,0,0.35)",
      backgroundColor: "#fff",
      px: 4,
      py: 1,
      mb: 5,
    },
  },
}

console.log("ThemeUI theme", theme)

export default theme
