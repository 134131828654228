/** @jsx jsx */
import React from "react"
import Layout from "./Layout"
import { Styled, jsx } from "theme-ui"

const Page = ({ children }) => (
  <Layout>
    <div sx={{ variant: "styles.shadowBox" }}>{children}</div>
  </Layout>
)

export default Page
